<template>
  <div class="card card-body shadow-sm infomations-list">
    <kyy-app-modal-sending-invoice
      ref="apikey_modal_sending_invoice"
      :modal-header-title="$t('student_invoice.modal_title')"
      @confirmed="handleSendingInvoice"
    >
      <template v-slot:body>
        <validation-observer
          ref="form_create_entry"
          v-slot="{ passes, valid, validated }"
        >
          <form class="form-horizontal">
            <div class="row">
              <div class="col-12">
                  <!-- Mail subject -->
                  <div class="form-group row">
                    <label class="col-md-3 col-sm-12">{{ $t("student_invoice.label_mail_subject") }} <span class="app-require">＊</span></label>
                    <div class="col-md-9 col-sm-12">
                      <app-input
                        name="mail_subject"
                        v-model="mail_data.mail_subject"
                      />
                    </div>
                  </div>

                  <!-- Mail content -->
                  <div class="form-group row">
                    <label class="col-md-3 col-sm-12">{{ $t("student_invoice.label_mail_content") }} <span class="app-require">＊</span></label>
                    <div class="col-md-9 col-sm-12">
                      <app-textarea
                        name="mail_content"
                        v-model="mail_data.mail_content"
                      />
                    </div>
                  </div>

                  <div class="row justify-content-end">
                    <button type="button" class="btn btn-default m-r-10" @click="closeModal">
                      {{ $t('common_vn.cancel') }}
                    </button>
                    <button :disabled="(!valid && validated) || isSending" class="btn btn-primary m-r-10" type="button" @click="handleSendingInvoice" >
                      {{ $t("student_invoice.button_confirm_send") }}
                    </button>
                  </div>
                <!-- /.card -->
              </div>
            </div>
          </form>
        </validation-observer>
      </template>
    </kyy-app-modal-sending-invoice>
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <app-basic-table
        ref="basic_table"
        :table-name="'Test'"
        :filters.sync="filters"
        :setting-columns="columns"
        :endpoint-list="ENDPOINT.KYY_STUDENT_INVOICE_LIST"
        @searched="onSearch"
      >
        <template v-slot:filters>
          <div class="row">
            <!-- Search by order date -->
            <div class="col-xl-3 col-lg-12 col-sm-12">
              <div class="form-group">
                <label class="col-xl-12 col-lg-12 col-sm-8">{{ filters.year_month.label }}</label>
                <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                  <app-date-range-picker
                    :name="filters.year_month.name"
                    v-model="filters.year_month.value"
                    timePicker24Hour="false"
                    format="YYYY-MM"
                    yearAndMonthOnly="true"
                    @input="filters.year_month.value = $event"
                  />
                </div>
              </div>
            </div>
            <!-- Search by order code -->
            <div class="col-xl-3 col-lg-12 col-sm-12">
                <div class="form-group">
                  <label class="col-xl-12 col-lg-12 col-sm-8">{{ filters.kyy_customer_id.label }}</label>
                  <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck mt-0">
                    <app-select :name="filters.kyy_customer_id.name"
                      input-style="normal"
                      v-model="filters.kyy_customer_id.value"
                      :options-data="meta.customers"
                    />
                  </div>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:table-menu-right>
          <button @click="handleClickSendButton()" class="btn btn-primary mr-3">
            {{ $t("student_invoice.button_send_invoices") }}
          </button>
          <button @click="handleBtnCreateClick()" class="btn btn-warning">
            {{ $t("student_invoice.button_create_month_invoices") }}
          </button>
        </template>

        <template v-slot:body-cell-user_name="props">
          <td class="app-align-middle">
            {{ props.row.customer.user_name }}
          </td>
        </template>

        <template v-slot:body-cell-email="props">
          <td class="app-align-middle">
            {{ props.row.customer.email }}
          </td>
        </template>

        <template v-slot:body-cell-phone_number="props">
          <td class="app-align-middle">
            {{ props.row.customer.phone_number }}
          </td>
        </template>


        <template v-slot:body-cell-status="props">
          <td class="app-align-middle text-center">
            {{ getLabelFromOptionArray(props.row.status, STUDENT_INVOICE_CONSTANT.STUDENT_INVOICE_STATUS) }}
          </td>
        </template>

        <template v-slot:body-cell-pdf="props">
          <td class="app-align-middle text-center">
            <a :href="props.row.url">PDF</a>
          </td>
        </template>
      </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
import moment from 'moment';
import AppBasicTable from "@components/_common/list/AppBasicTable";
import {STUDENT_INVOICE_CONSTANT} from "@constants/kyy_student_invoice";
import {getLabelFromOptionArray} from "@utils";
import KyyAppModalSendingInvoice from '../../../components/_common/popup/KyyAppModalSendingInvoice.vue';
import AppTextarea from '../../../components/forms/AppTextarea.vue';
import AppInput from '../../../components/forms/AppInput.vue';

export default {
  name: "InvoiceSendingPage",
  components: {
      "app-basic-table": AppBasicTable,
      KyyAppModalSendingInvoice,
    AppTextarea,
    AppInput,
    },
  data() {
    return {
      sell_date: null,
      isShowTable: false,
      filters: {
        year_month: {
          name: "year_month",
          condition: "equal",
          label: this.$t("student_invoice.year_month"),
          value: this.$route.query['filters.year_month.equal'],
        },
        kyy_customer_id: {
          name: "kyy_customer_id",
          condition: "equal",
          label: this.$t("student_invoice.customer_code"),
        },
      },
      columns: [
        {name: "user_name", label: this.$t("student_invoice.customer_code"), textAlign: 'text-center'},
        {name: "email", label: this.$t("student_invoice.email"), sortable: true, textAlign: 'text-center'},
        {name: "phone_number", label: this.$t("student_invoice.phone_number"), sortable: true, textAlign: 'text-center'},
        {name: "status", label: this.$t("student_invoice.status"), textAlign: 'text-center'},
        {name: "pdf", label: this.$t("student_invoice.invoice"), textAlign: 'text-center'},
      ],
      meta: [],
      mail_data: {
        mail_subject: null,
        mail_content: null
      },
      isSending: false
    }
  },

  methods: {
    handleBtnCreateClick() {
      this.$router.push({name: this.ROUTES.ADMIN.KYY_STUDENT_INVOICE_CREATE})
    },

    onSearch() {
      // this.getTourSelectName();
    },

    doSearch() {
      this.isShowTable = true
      this.$refs.basic_table.getEntries(false);
    },

    closeModal() {
      this.$refs.apikey_modal_sending_invoice.hide();
    },

    handleClickSendButton() {
      this.initMailData()
      this.$refs.apikey_modal_sending_invoice.show();
    },

    async handleSendingInvoice() {
      this.isSending = true;
      let res = null;
      let msg = "";
      let params = {
        year_month: moment(this.filters.year_month.value, 'YYYY-MM').format('YYYY-MM-DD'),
        kyy_customer_id: this.filters.kyy_customer_id.value,
        mail_subject: this.mail_data.mail_subject,
        mail_content: this.mail_data.mail_content
      }
      let exportDate = moment(this.sell_date, 'YYYY-MM-DD').format('MM-DD-YYYY');
      res = await this.$request.post(this.ENDPOINT.KYY_STUDENT_INVOICE_SEND, params);
      msg = this.$t("student_invoice.send_ok")
      this.isSending = false;
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        this.__noticeSuccess(msg);
        this.closeModal();
      }
    },

    initMailData() {
      const month = moment(this.filters.year_month.value, 'YYYY-MM').format('MM')
      this.mail_data.mail_subject = `【請求書】紀伊/寛日本人学校ランチサービス${month}月分`
      this.mail_data.mail_content = `日本人学校お弁当注文保護者各位さま いつもお弁当のご注文ありがとうございます。月分お弁当代金請求書を添付します。`
            + `${month}「銀行振込先は以下のとおりです」 振込先口座番号：54450666868 VŨ VĂN TUẤN - NGÂN HÀNG TMCP QUÂN ĐỘI - MB BANK 請求書をご確認の上、支払い期限は学校集金日と同じです。`
            + `ご不明な点がございましたら、 お弁当担当MSザーンまでご連絡下さい、ありがとうございます。`
    }
  },

  created: function () {
    this.initMailData()
  },

  mounted: function () {
    this.$request.get(this.ENDPOINT.UI_HELPER_KYY_STUDENT_ORDER).then(res => {
      let customers = _.cloneDeep(res.data.customers);
      customers = customers.map((customer) => {
        return {
          id: customer?.id,
          name: customer?.user_name
        }
      })
      customers.unshift({
        id: 'all',
        name: '',
      });
      this.meta = {
        customers: customers,
      }
    })
  },
}
</script>
